import React from 'react';

function SvgChevronLeft() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-current text-gray-800">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M14.71 6.71a.996.996 0 00-1.41 0L8.71 11.3a.996.996 0 000 1.41l4.59 4.59a.996.996 0 101.41-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z" />
    </svg>
  );
}

export default SvgChevronLeft;
