import React from 'react';

function SvgChevronRight() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-current text-gray-800">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M9.29 6.71a.996.996 0 000 1.41L13.17 12l-3.88 3.88a.996.996 0 101.41 1.41l4.59-4.59a.996.996 0 000-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
    </svg>
  );
}

export default SvgChevronRight;
