import classNames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  superScript?: string;
}

const Title = ({ children, className, superScript }: Props) => {
  const styles = classNames('text-3xl md-text-4xl font-bold text-left', className);
  return (
    <h1 className={styles}>
      {children}
      {superScript && <sup className="font-normal text-base" style={{ top: '-1.5rem' }}>{superScript}</sup>}
    </h1>
  );
};

export default Title;
