import React, { FC } from 'react';
import { scroller } from 'react-scroll';

interface Props {
  image: string;
  name: string;
  anchor: string;
}

const ServiceButton: FC<Props> = ({ image, name, anchor }: Props) => {
  const handleClick = (to: string) => {
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
    });
  };
  return (
    <div className="flex w-1/2 lg-w-1/4 px-2 py-2">
      <button
        onClick={() => handleClick(anchor)}
        className="flex flex-1 flex-col items-center justify-center p-4 shadow rounded hover-shadow-lg animation-transition">
        <img src={image} width={100} alt=""/>
        <p className="font-bold text-sm">{name}</p>
      </button>
    </div>
  );
};

export default ServiceButton;
