import React, { FC } from 'react';
import style from './style.module.scss';

type Props = React.HTMLAttributes<HTMLElement>;

const OffsetCard: FC<Props> = ({ children }: Props) => {
  return (
    <div className={`${style.CardOffset} w-3/4 bg-white rounded-lg p-6 border border-gray-300 text-center mx-auto`}>
      <p className="text-base text-primary-dark">{children}</p>
    </div>
  );
};

export default OffsetCard;
