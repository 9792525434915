import React, { FC, useEffect, useState } from 'react';

import fetchApi from '../../utils/ApiService';
import { AssetsUrl, ContentUrl, LoginUrl } from '../../utils/constants';
import Logger from '../../utils/Logger';
import Button, { ButtonVariant } from '../Button';
import Carousel from '../Carousel';
import ImageResponsive from '../ImageResponsive';
import LayoutContainer from '../LayoutContainer';
import Title from '../Title/Title';

import style from './style.module.scss';
import { isInternetExplorer } from '../../utils/browser';

interface CarouselImage {
  alt: string;
  images: string[];
  position: number;
  src: string;
}

const IntroductionSection: FC<{}> = () => {
  const [carouselImages, setCarouselImages] = useState<CarouselImage[]>([]);

  const isIE = isInternetExplorer();

  useEffect(() => {
    fetchApi<CarouselImage[]>(ContentUrl + '/carousel.json')
      .then((images: CarouselImage[]) => setCarouselImages(images))
      .catch((err: Error) => Logger.error('error while getting carousel.json', err));
  }, []);

  const sortCarouselImages = (imgA: CarouselImage, imgB: CarouselImage) =>
    imgA.position - imgB.position;

  const renderCarouselImages = (img: CarouselImage, index: number) => {
    return (
      <div className="text-center" key={index}>
        <ImageResponsive
          className="mx-auto"
          sizes="(max-width: 1400px) 100vw, 1400px"
          lazy={true}
          srcSet={img.images.map(str => `${AssetsUrl}/images/carousel/${str}`).join(', ')}
          src={`${AssetsUrl}/images/carousel/${img.src}`}
          alt={img.alt}
        />
      </div>
    );
  };

  const renderGovTechGif = () => {
    return (
      <img
        width={200}
        src={`${AssetsUrl}/images/govtech-logo/logo_govtech_hort.gif`}
        alt="govtech_logo"
      />
    );
  };

  const renderGovTechVideo = () => {
    return (
      <video width={200} autoPlay={true} loop={true} muted={true} playsInline={true}>
        <source src={`${AssetsUrl}/images/govtech-logo/govtech.webm`} type="video/webm" />
        <source src={`${AssetsUrl}/images/govtech-logo/govtech.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  return (
    <section className={`relative bg-white ${style.WaveSvg}`}>
      <LayoutContainer className="px-2 sm-px-4 sm-pt-20 lg-pt-10" style={{ maxWidth: 1280 }}>
        <div className="flex flex-wrap">
          <div className="w-full sm-w-full md-w-1/2 px-4 sm-px-0">
            <div className="lg-w-3/4">
              <div className="my-4">{isIE ? renderGovTechGif() : renderGovTechVideo()}</div>
              <Title className="text-center sm-text-left">
                Improve Government
                <br /> Services with <span className="text-primary-medium">Data</span>
              </Title>
              <p className="my-4 text-base text-center sm-text-left">
                Make informed decisions using WOGAA's data collected across our services.
              </p>
              <div className="flex flex-col items-center justify-between sm-block">
                <Button
                  href={LoginUrl}
                  className="inline-block font-bold"
                  cons={true}
                  variant={ButtonVariant.PrimaryMedium}
                >
                  SIGN UP NOW
                </Button>
              </div>
            </div>
          </div>
          <div className="w-full sm-w-full md-w-1/2">
            <div className="pt-10 sm-pt-32 md-pt-40 xl-pt-10 text-center">
              <Carousel>
                {carouselImages.sort(sortCarouselImages).map(renderCarouselImages)}
              </Carousel>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </section>
  );
};

export default IntroductionSection;
