import React from 'react';
import MSTeamDesktop from '../../assets/images/ms_logo_desktop.svg';
import MSTeamMobile from '../../assets/images/ms_logo_mobile.svg';
import FlexContainer from '../FlexContainer';
import ImageResponsive from '../ImageResponsive';
import FooterLink from './FooterLink';
import { PRIVACY_STATEMENT_ROUTE } from '../../AppRouter';

const Footer = () => {
  return (
    <section className="bg-primary-dark text-white">
      <FlexContainer
        justifyContent="start"
        flexDirection="col"
        className="py-2 sm:mx-8 md:mx-16 sm-flex-row sm-justify-between">
        <FlexContainer flexDirection="col" className="sm-flex-row sm-items-center">
          <FooterLink type="href" to="https://www.tech.gov.sg/report_vulnerability">
            Report Vulnerability
          </FooterLink>
          <FooterLink type="link" to={PRIVACY_STATEMENT_ROUTE}>
            Privacy Statement
          </FooterLink>
        </FlexContainer>
        <FlexContainer alignItems="center">
          <FooterLink to="https://go.gov.sg/joinwogaa-teams">
            <FlexContainer alignItems="center">
              Join our community on{' '}
              <ImageResponsive lazy={true} src={MSTeamDesktop} alt="microsoft-team-img" className="px-1 hidden sm-block" />
              <ImageResponsive lazy={true} src={MSTeamMobile} alt="microsoft-team-img" className="px-1 block sm-hidden" />

            </FlexContainer>
          </FooterLink>
        </FlexContainer>
      </FlexContainer>
    </section>
  );
};

export default Footer;
