import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export enum ButtonVariant {
  PrimaryMedium = 'btn-primary-medium',
  PrimaryDark = 'btn-primary-dark',
  PrimaryOutlineWhite = 'btn-primary-outline-white',
}

type Size = 'sm' | 'md' | 'lg';

interface BaseProps {
  cons?: boolean;
  variant: ButtonVariant;
  isDisabled?: boolean;
  size?: Size;

  [key: string]: any;
}

interface AnchorProps extends BaseProps {
  href: string;
}

interface LinkProps extends BaseProps {
  to: string;
}

type Props = BaseProps | AnchorProps | LinkProps;

const Button: FC<Props> = ({
  children,
  className = '',
  cons = false,
  href,
  isDisabled = false,
  size = 'md',
  to,
  variant,
  ...props
}: Props) => {
  const cx = classNames(`btn`, 'cursor-pointer', 'animation-transition', variant, size, className, {
    'btn-cons': cons,
    'disabled': isDisabled,
  });

  if (href) {
    return (
      <a className={cx} href={href} {...props}>
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link className={cx} to={to} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button className={cx} {...props}>
      {children}
    </button>
  );
};

export default Button;
