import React from 'react';

type Props = React.HTMLAttributes<HTMLOrSVGElement>;

function SvgRoundExpandLess({ className, ...props }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`fill-current text-gray-800 ${className}`}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M11.29 8.71L6.7 13.3a.996.996 0 101.41 1.41L12 10.83l3.88 3.88a.996.996 0 101.41-1.41L12.7 8.71a.996.996 0 00-1.41 0z" />
    </svg>
  );
}

export default SvgRoundExpandLess;
