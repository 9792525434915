import React, { FC, useState } from 'react';
import { MenuItem } from '../../../entities/MenuItem';
import SvgRoundExpandLess from '../../../icons/SvgRoundExpandLess';
import SvgRoundExpandMore from '../../../icons/SvgRoundExpandMore';
import NavItem from '../NavItem';

import styles from './style.module.scss';

export interface ListItem {
  menuItem: MenuItem;
  id: string;
}

interface Props {
  listItems: ListItem[];
  triggerName: JSX.Element | string;
}

const NavDropdown: FC<Props> = ({ listItems, triggerName }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMouseEnter = () => setMenuOpen(true);
  const handleMouseLeave = () => setMenuOpen(false);
  const handleClick = (menuItem: MenuItem) => {
    if (menuItem.onClick) {
      menuItem.onClick();
    }
    setMenuOpen(false);
  };

  const renderListItems = (listItem: ListItem) => {
    const { id, menuItem } = listItem;
    return (
      <NavItem
        key={id}
        onClick={_ => handleClick(menuItem)}
        type={menuItem.type}
        path={menuItem.value}
      >
        {menuItem.label}
      </NavItem>
    );
  };

  return (
    <div
      className={styles.NavDropdown}
      onTouchStart={handleMouseEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className={styles.NavItem}>
        <span>{triggerName}</span>
        {isMenuOpen ? <SvgRoundExpandLess /> : <SvgRoundExpandMore />}
      </button>
      {isMenuOpen && <div className={styles.Menu}>{listItems.map(renderListItems)}</div>}
    </div>
  );
};

export default NavDropdown;
