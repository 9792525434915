class Logger {

  public static info(...args: any) {
    if (localStorage.getItem(this.LOGGER)) {
      console.log(args);
    }
  }

  public static error(message: string, error?: Error) {
    if (localStorage.getItem(this.LOGGER)) {
      console.error(message, error);
    }
  }

  private static LOGGER = 'wogaa.product.logger';
}

export default Logger;
