import classNames from 'classnames';
import React, { FC } from 'react';

type JustifyContent = 'center' | 'between' | 'start' | 'around';
type AlignItems = 'center' | 'start' | 'stretch';
type FlexDirection = 'row' | 'col';
type FlexWrap = 'wrap';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  flexDirection?: FlexDirection;
  flexWrap?: FlexWrap;
}

const FlexContainer: FC<Props> = ({
  children,
  justifyContent,
  alignItems,
  flexDirection,
  flexWrap,
  className,
  ...props
}: Props) => {

  const styles = classNames(
    'flex',
    {
      'flex-col': flexDirection === 'col',
      'flex-row': flexDirection === 'row',
      'flex-wrap': flexWrap === 'wrap',
      'items-center': alignItems === 'center',
      'items-start': alignItems === 'start',
      'items-stretch': alignItems === 'stretch',
      'justify-around': justifyContent === 'around',
      'justify-between': justifyContent === 'between',
      'justify-center': justifyContent === 'center',
      'justify-start': justifyContent === 'start',
    },
    className,
  );

  return <div className={styles} {...props}>{children}</div>;
};

export default FlexContainer;
