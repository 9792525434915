import React, { FC } from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const higherOrderComponent = (styles: string) => {
  return ({ children, ...props }: Props) => {
    return <div className={styles} {...props}>{children}</div>;
  };
};

const ServiceLayoutBlock: FC<Props> = higherOrderComponent('w-full md-w-1/2 flex justify-center items-center');
const ServiceLayout: FC<Props> = higherOrderComponent('hidden md-flex w-1/2 justify-center items-center text-center');

export {
  ServiceLayout,
  ServiceLayoutBlock,
};
