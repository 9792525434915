import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

import AppRouter from './AppRouter';
import Navbar from './components/Navbar/Navbar';

const App: React.FC = () => {

  return (
    <Router>
      <Navbar />
      <AppRouter />
    </Router>
  );
};

export default App;
