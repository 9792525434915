import classNames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLElement[]> {
  fullWidth?: boolean;
}

const InsightCard = ({ children, fullWidth = false }: Props) => {
  const className = classNames('px-2', {
    'w-1/2': !fullWidth,
    'w-full': fullWidth,
  });
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default InsightCard;
