import classNames from 'classnames';
import React, { FC } from 'react';

export enum PFontSize {
  ExtraSmall = 'text-xs',
  Base = 'text-base',
  Large = 'text-lg',
  XL2 = 'text-2xl',
}

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  isBold?: boolean;
  size?: PFontSize;
}

const Paragraph: FC<Props> = ({
  children,
  className,
  isBold = false,
  size = PFontSize.Base,
}: Props) => {
  const styles = classNames(size, {
    'font-bold': isBold,
  }, className);
  return (<p className={`${styles}`}>{children}</p>);
};

export default Paragraph;
