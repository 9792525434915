import React, { FC } from 'react';

interface Props {
  name: string;
}

const TitleSeparator: FC<Props> = ({ name }: Props) => {
  return (
    <div className="separator">
      <h1 className="text-xl md:text-2xl font-bold text-primary-dark">{name}</h1>
    </div>
  );
};

export default TitleSeparator;
