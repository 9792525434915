import React, { FC } from 'react';

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  width?: number;
  src: any;
  alt: string;
  lazy?: boolean;
  sizes?: string;
  srcSet?: string;
}

const ImageResponsive: FC<Props> = ({ lazy, alt, srcSet, ...props }: Props) => {
  return <img srcSet={srcSet} alt={alt} {...{ ...(lazy && { loading: lazy ? 'lazy' : 'eager' }), ...props }}/>;
};

export default ImageResponsive;
