import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const PageContainer = ({ children, ...props }: Props) => {
  return (
    <div className={`mt-10 md-mt-16`} {...props}>
      {children}
    </div>
  );
};

export default PageContainer;
