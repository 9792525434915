import React from 'react';

type Props = React.HTMLAttributes<HTMLOrSVGElement>;

function SvgRoundExpandMore({ className, ...props }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`fill-current text-gray-800 ${className}`}
      {...props}
    >
      <path opacity={0.87} fill="none" d="M24 24H0V0h24v24z" />
      <path d="M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 10-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 000-1.41c-.39-.38-1.03-.39-1.42 0z" />
    </svg>
  );
}

export default SvgRoundExpandMore;
