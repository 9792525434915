import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EVENTS_ROUTE,
  FAQ_ROUTE,
  INSPECT_ROUTE,
  SENTIMENTS_ROUTE,
  UPTIME_ROUTE,
  WEB_ANALYTICS_ROUTE,
} from '../../AppRouter';
import { DocumentationUrl, LoginUrl } from '../../utils/constants';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';
import { ButtonVariant } from '../Button';

export enum MobilePanel {
  Main = 'MAIN',
  None = 'NONE',
  Services = 'SERVICES',
  Resources = 'RESOURCES',
}

const Navbar = () => {
  const navigate = useNavigate();
  const menuList = [
    {
      dropdown: [
        {
          label: 'Analytics',
          onClick: () => navigate(WEB_ANALYTICS_ROUTE),
          type: 'link',
          value: WEB_ANALYTICS_ROUTE,
        },
        {
          label: 'Uptime',
          onClick: () => navigate(UPTIME_ROUTE),
          type: 'link',
          value: UPTIME_ROUTE,
        },
        {
          label: 'Inspect',
          onClick: () => navigate(INSPECT_ROUTE),
          type: 'link',
          value: INSPECT_ROUTE,
        },
        {
          label: 'Sentiments',
          onClick: () => navigate(SENTIMENTS_ROUTE),
          type: 'link',
          value: SENTIMENTS_ROUTE,
        },
      ],
      label: 'Our Products',
      panel: MobilePanel.Services,
    },
    {
      label: 'Documentation',
      onClick: () => window.open(DocumentationUrl, '_blank'),
      panel: MobilePanel.Resources,
      type: 'link',
      value: DocumentationUrl,
    },
    {
      label: 'Events',
      onClick: () => navigate(EVENTS_ROUTE),
      panel: MobilePanel.Main,
      type: 'link',
      value: EVENTS_ROUTE,
    },
    {
      label: 'FAQ',
      onClick: () => navigate(FAQ_ROUTE),
      panel: MobilePanel.Main,
      type: 'link',
      value: FAQ_ROUTE,
    },
    {
      label: 'SIGN UP',
      onClick: () => (window.location.href = LoginUrl),
      type: 'button',
      value: LoginUrl,
      variant: ButtonVariant.PrimaryOutlineWhite,
    },
    {
      label: 'LOGIN',
      onClick: () => (window.location.href = LoginUrl),
      type: 'button',
      value: LoginUrl,
      variant: ButtonVariant.PrimaryMedium,
    },
  ];

  return (
    <Fragment>
      <div className="hidden md-block md-fixed top-0 left-0 right-0 z-50">
        <NavbarDesktop menuList={menuList} />
      </div>
      <div className="block md-hidden fixed top-0 left-0 right-0 z-50">
        <NavbarMobile menuList={menuList} />
      </div>
    </Fragment>
  );
};

export default Navbar;
