import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles.css';

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer!);
root.render(<App />);