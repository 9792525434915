import React, { CSSProperties, FC, ReactNode } from 'react';
import Button, { ButtonVariant } from '../../Button';

import styles from './style.module.scss';

interface Props {
  children: ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  type?: string;
  path?: string;
  style?: CSSProperties;
  variant?: ButtonVariant;
}

const NavItem: FC<Props> = ({ children, onClick, path, style, type, variant }) => {
  if (type && type === 'button') {
    return (
      <div className={`${styles.NavButtonItem}`} style={style}>
        <Button
          onClick={onClick}
          className="btn cursor-pointer animation-transition btn-primary-medium font-bold text-white sm"
          data-path={path}
          variant={variant}
        >
          {children}
        </Button>
      </div>
    );
  } else {
    return (
      <a className={styles.NavItem} onClick={onClick} data-path={path} style={style}>
        {children}
      </a>
    );
  }
};

export default NavItem;
