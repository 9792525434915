import React, { FC, Fragment } from 'react';
import { LoginUrl } from '../../utils/constants';
import Button, { ButtonVariant } from '../Button';
import Paragraph from '../Paragraph';
import Title from '../Title';

import './style.scss';

interface Props {
  title: string;
  subTitle?: string;
}

const SignUpMountain: FC<Props> = ({ title, subTitle }: Props) => {
  return (
    <Fragment>
      <div className="mountain-svg" />
      <div className="bg-info pb-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col flex-wrap items-center justify-center">
            <Title className="mb-5 text-center">
              <span className="text-2xl lg-text-3xl">{title}</span>
            </Title>
            {subTitle && <Paragraph className="text-lg mb-5">{subTitle}</Paragraph>}
            <Button
              className="mb-5"
              href={LoginUrl}
              cons={true}
              variant={ButtonVariant.PrimaryMedium}
            >
              SIGN UP TODAY
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUpMountain;
