/**
 * Api service HTTP request
 * @param url
 */
function fetchApi<T>(url: string): Promise<T> {
  return fetch(url).then((response: Response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

export default fetchApi;
