import React from 'react';

function SvgBulb() {
  return (
    <svg width="24" height="25" viewBox="0 0 22 23" className="fill-current text-transparent inline-block">
      <path
        d="M11.051 18.897H8.99c-.773-.828.516-1.759-1.185-3.104-2.113-1.81-2.118-5.396 0-7.293 1.012-.907 1.89-1.242 3.247-1.241"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 18.897h2.062c.773-.828-.516-1.759 1.186-3.104 2.113-1.81 2.117-5.396 0-7.293-1.013-.907-1.89-1.242-3.248-1.241"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={10.382}
        y={1.104}
        width={1.34}
        height={3.931}
        rx={0.67}
        stroke="#000"
      />
      <rect
        width={1.343}
        height={3.924}
        rx={0.671}
        transform="matrix(.69822 .71589 -.71348 .70067 17.717 3.379)"
        stroke="#000"
      />
      <rect
        width={1.343}
        height={3.924}
        rx={0.671}
        transform="matrix(-.69822 .71589 .71348 .70068 4.412 3.379)"
        stroke="#000"
      />
      <rect
        x={20.897}
        y={10.104}
        width={1.345}
        height={3.918}
        rx={0.672}
        transform="rotate(90 20.897 10.104)"
        stroke="#000"
      />
      <rect
        x={5.021}
        y={10.104}
        width={1.345}
        height={3.918}
        rx={0.672}
        transform="rotate(90 5.02 10.104)"
        stroke="#000"
      />
      <rect
        x={12.959}
        y={19.725}
        width={1.345}
        height={3.918}
        rx={0.672}
        transform="rotate(90 12.959 19.725)"
        stroke="#000"
      />
      <rect
        x={11.928}
        y={21.379}
        width={0.517}
        height={1.649}
        rx={0.259}
        transform="rotate(90 11.928 21.379)"
        stroke="#000"
      />
    </svg>
  );
}

export default SvgBulb;
