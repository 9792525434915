import React, { FC } from 'react';
import BackToTopButton from '../../components/BackToTopButton';
import Footer from '../../components/Footer';
import IntroductionSection from '../../components/IntroductionSection';
import OurServices from '../../components/OurServices';
import PageContainer from '../../components/PageContainer';

const Home: FC<{}> = () => {
  return (
    <PageContainer>
      <IntroductionSection/>
      <OurServices/>
      <BackToTopButton/>
      <Footer/>
    </PageContainer>
  );
};

export default Home;
