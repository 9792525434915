import React, { FC, useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import SvgRoundExpandLess from '../../icons/SvgRoundExpandLess';
import FlexContainer from '../FlexContainer';
import Paragraph from '../Paragraph';
import style from './style.module.scss';

const BackToTopButton: FC<{}> = () => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { pageYOffset } = window;
      setActive(pageYOffset > 200);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = () => {
    animateScroll.scrollToTop({
      duration: 500,
    });
  };

  return (
    <button
      className={`fixed z-50 cursor-pointer ${style.Position} ${isActive ? `block ${style.FadeUpAnimate}` : 'hidden'}`}
      onClick={handleClick}>
      <div
        className={`relative overflow-hidden rounded-full bg-primary-medium shadow-lg ${style.RoundSize}`}>
        <FlexContainer alignItems="center" justifyContent="center" flexDirection="col">
          <SvgRoundExpandLess/>
          <Paragraph className="text-white">TOP</Paragraph>
        </FlexContainer>
      </div>
    </button>
  );

};

export default BackToTopButton;
