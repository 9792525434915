import React from 'react';
import FlexContainer from '../FlexContainer';
import './style.scss';

const Spinner = () => {
  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <div className="app-loading">
        <svg className="spinner" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
        </svg>
      </div>
    </FlexContainer>
  );
};

export default Spinner;
