import React from 'react';
import { Link } from 'react-router-dom';

type LinkType = 'link' | 'href';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type?: LinkType;
  to: string;
}

const FooterLink = ({ children, to = 'link', type }: Props) => {
  if (type === 'link') {
    return <Link to={to} className="px-2 md-px-4 py-2 md-py-4 text-white">{children}</Link>;
  } else {
    return <a href={to} target="_blank" rel="noopener noreferrer"
              className="px-2 md-px-4 py-2 md-py-4 text-white">{children}</a>;
  }
};

export default FooterLink;
